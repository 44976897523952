#root{
    max-width: 768px;
    min-width: 360px;
    margin: 0 auto;
    min-height: 100vh;
    background-color: rgb(255,255,255);
    box-sizing: border-box
}

body,
h1,h2,h3,h4,h5,h6,p,
ul,li,ol{
    margin: 0;
    padding: 0;
}


a {
    text-decoration: none;
}

li {
    list-style: none;
}

button,
input,
form,
fieldset,
select {
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    border-radius: none;
    outline: none;
}


